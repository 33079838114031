import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/LayoutView'
import PropertyListing from '../components/PropertyListing'
import Breadcrumb from "../components/PropertyDetails/breadcrumb";
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'

const CategoryTypeByLocality = ({ data, pageContext }) => {
  const { propertyInfo, properties, categoryInfo, propertyType} = data
  const title = `${propertyType.name} ${categoryInfo.name} in ${propertyInfo.name}, ${propertyInfo.city.name}`
  const type = `${propertyType.name} ${categoryInfo.name} in ${propertyInfo.name} `
  const city = `${propertyInfo.city.name}`
  const citySlug = `property/${categoryInfo.slug}/${propertyType.slug}/${propertyInfo.city.slug}`
  const description = `Check out our listing of ${title}`
  const url = `property/${categoryInfo.slug}/${propertyType.slug}/${propertyInfo.slug}/${propertyInfo.name}`


  const items = [
    { to: '/', label: 'Home' },
    {to: `/${citySlug}`, label: city },
    { label: type }
  ]
  return (
    <Layout>
       <GatsbySeo
      title={title}
      
      openGraph={{
        title: {title},
        description: {description},
        url: {url},
        images: 
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/131ePdA3lUnISWi6UzYfq9/ef10c36ff7cef5ec33baa54fc809c167/apartments-and-houses-for-sale-ikate.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Og Image Alt Article Title A',
          },
        
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    /> 

<BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Home',
          item: '/',
        },
        {
          position: 2,
          name: {city},
          item: `/${citySlug}`,
        },

        {
          position: 3,
          name: {type},
          item: `/${url}`,
        },
      ]}
    />
      
      
            
      
      <div className='pt-5 pd-bottom-70 '>
     <div className='mt-5 container'>
     <h1 className='h4 text-center'>{title}</h1>
      <Breadcrumb className='mt-5' >
              {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                  {label}
                </Link>
              ))}
            </Breadcrumb>
       </div>
        <PropertyListing
        
          properties={properties}
          pageContext={pageContext}
          pathname={`/property/${categoryInfo.slug}/${propertyType.slug}/${propertyInfo.city.slug}/${propertyInfo.slug}`}
        />
      </div>
    </Layout>
  )
}

export default CategoryTypeByLocality

export const query = graphql`
  query allPropertyTypeByLocalityQuery(
    $slug: String!
    $type: String!
    $category: String!
    $city: String!
  ) {
    propertyInfo: contentfulLocalityInCity(slug: { eq: $slug }) {
      id
      name
      slug
      city {
        slug
        name
      }
    }

    propertyType: contentfulPropertyCategoryByType(slug: { eq: $type }) {
      name
      slug
      id
    }

    categoryInfo: contentfulPropertyCategory(slug: { eq: $category }) {
      id
      slug
      name
    }

    properties: allContentfulProperty(
      filter: {
        propertyType: { slug: { eq: $type } }
        propertyCategoryByCity: { slug: { eq: $city } }
        locality: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...Property
      }
      ...PropertyPagination
    }
  }
`
